import { getAuthData } from "utils/AuthUtil";
import { REDIRECTION_BTN_TYPES } from "./Redirection.data";
import { schedule_types_ids } from "constants/schedule";
import api from "data/APIs";
import { isValidMobile, is_empty, validURLV2 } from "utils/validations";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { logError } from "utils/error";

export const getDefaultRedirectionData = (btnType, listingData) => {
  let defaultRedirectionData = {
    ctaText: "",
    ctaUrlInfo: "",
  };
  const { ctaText } = getDataForDefaultRedirection(listingData);
  switch (btnType) {
    case REDIRECTION_BTN_TYPES.custom:
      defaultRedirectionData.ctaText =
        listingData.metadata.post_booking_cta_text || "Continue";
      defaultRedirectionData.ctaUrlInfo =
        listingData.metadata.post_booking_cta_url || "";
      break;
    case REDIRECTION_BTN_TYPES.whatsapp:
      defaultRedirectionData.ctaText = "Get in touch";
      defaultRedirectionData.ctaUrlInfo = `${getAuthData().country_code}${
        getAuthData().phone_number
      }`;
      break;
    case REDIRECTION_BTN_TYPES.telegram:
      defaultRedirectionData.ctaText = "Get in touch";
      break;
    default:
      defaultRedirectionData.ctaText = ctaText;
      break;
  }
  return defaultRedirectionData;
};

export const getDataForDefaultRedirection = (listingData) => {
  let data = {
    ctaText: "",
    infoText:
      "For the default button type, your customers will see a button labeled “Continue”. Clicking this button will take them to the checkout page.",
  };
  switch (listingData.type) {
    case schedule_types_ids.content:
      data.ctaText = "View Content";
      data.infoText =
        "For the default button type, your customers will see a button labeled “View Content”. Clicking this button will take them to the course page.";
      break;
    case schedule_types_ids.branded_community:
      data.ctaText = "Access the Community now!";
      data.infoText =
        "For the default button type, your customers will see a button labeled “Access the Community now!”. Clicking this button will take them to the community.";
      break;
    case schedule_types_ids.telegram:
      data.ctaText = "Open Telegram";
      data.infoText =
        "For the default button type, your customers will see a button labeled “Open Telegram”. Clicking this button will take them to Telegram.";
      break;
    default:
      break;
  }
  return data;
};

export const savePostBookingUrlData = async ({
  postBookingData,
  listingData,
}) => {
  try {
    await dataProvider.custom_request(api.update_listing, apiMethods.POST, {
      uuid: listingData.uuid,
      updated_values: {
        metadata: {
          post_booking_cta_text: postBookingData.ctaText || "",
          post_booking_url: postBookingData.ctaUrlInfo,
        },
      },
    });
  } catch (err) {
    logError({
      error: err,
      occuredAt:
        "savePostBookingUrlData in src/ui/pages/ThankYou/components/Sections/Redirection/Redirection.utils.js",
      when: "saving thankyouPage redirection data",
    });
  }
};

// Add Section Vaildator here
export const validateSectionData = (data, handleError) => {
  const tempError = {};
  const { selectedBtnType, ctaText, redirectionUrl } = data || {};
  const countryCode = getAuthData().country_code;
  if (
    selectedBtnType !== REDIRECTION_BTN_TYPES.default &&
    selectedBtnType !== REDIRECTION_BTN_TYPES.none
  ) {
    if (is_empty(ctaText)) tempError.ctaText = true;
    switch (selectedBtnType) {
      case REDIRECTION_BTN_TYPES.whatsapp:
        if (
          is_empty(redirectionUrl) ||
          (selectedBtnType === REDIRECTION_BTN_TYPES.whatsapp &&
            !isValidMobile(
              countryCode,
              redirectionUrl.replace(countryCode, "")
            ))
        )
          tempError.ctaUrlInfo = true;
        break;
      default:
        if (is_empty(redirectionUrl) || !validURLV2(redirectionUrl))
          tempError.ctaUrlInfo = true;
    }
  }
  handleError && handleError(tempError);
  if (Object.keys(tempError).length > 0) return false;
  else return true;
};
